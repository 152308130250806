.pagination {
  margin: 0;
  padding: 0 !important;
  color: #7aba4c;
  z-index: 3;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: var(--primary);
  border-color: transparent;
  border-radius: 10px;
  margin-right: 2px;
  margin-left: 2px;
}
.page-item.disabled .page-link {
  color: var(--primary);
}
.page-link {
  border: none;
  color: var(--primary);
  background-color: transparent;
}
.page-link:hover {
  color: var(--primary);
  border-radius: 10px;
  /* background-color: transparent; */
}
